<template>
    <div v-cloak>
        <h4
            class="font-weight-regular"
            :class="labelClass"
            v-if="items.length === 0"
        >
            <span>{{ label }}</span>
            <v-icon size="10px" color="error" class="mb-1 ml-1">{{
                icon
            }}</v-icon>
        </h4>
        <div
            class="d-flex align-center mb-2"
            style="width: 300px"
            v-if="items.length > 0"
        >
            <v-select
                dense
                hide-details
                solo
                :value="label"
                :items="items"
                @change="$emit('update:label', $event)"
            ></v-select>
            <v-icon size="10px" color="error" class="mb-1 ml-1">{{
                icon
            }}</v-icon>
        </div>

        <slot></slot>
    </div>
</template>
<script>
export default {
    name: "form-element",
    props: {
        label: {
            type: String,
            required: true,
        },
        items: {
            type: Array,
            required: false,
            default: () => [],
        },
        required: {
            type: Boolean,
            default: false,
        },
        labelClass: {
            type: String,
            required: false,
            default: "",
        },
    },
    data() {
        return {
            icon: this.required ? "brightness_1" : "",
        };
    },
};
</script>
