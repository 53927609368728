import utils from "@/utils";

export const routes = [
    {
        name: "Login",
        path: "/login",
        meta: {
            requiresAuth: false,
            keepAlive: false,
            layout: "",
        },
        // eslint-disable-next-line no-unused-vars
        beforeEnter: (to, from, next) => {
            const {
                params: { returnUrl = "" },
            } = to;
            const loginUrl = utils.loginUrl(returnUrl);
            window.location.replace(loginUrl);
        },
    },
     {
        name: "Unauthorized",
        path: "/unauthorized",
        meta: {
            requiresAuth: false,
            keepAlive: false,
            layout: "public",
        },
        component: () => import("@components/users/views/UnauthorizedRequest"),
    },
    {
        path: "/",
        redirect: "/applications/history",
    },
    {
        path: "/playground",
        meta: {
            keepAlive: true,
            requiresAuth: true,
            layout: "auth", // specify layout for route here, we require an authorized layout for this route
        },
        component: () => import("@components/application/views/Playground.vue"),
    },
    {
        path: "/apply/applicant/register/",
        redirect: "/applications/create",
    },
    {
        name: "Applications",
        path: "/applications/history",
        meta: {
            keepAlive: true,
            requiresAuth: true,
            layout: "auth", // specify layout for route here, we require an authorized layout for this route
        },
        component: () =>
            import("@components/application/views/ApplicationHistory.vue"),
    },
    {
        name: "Edit Application",
        path: "/applications/applicant/edit",
        meta: {
            keepAlive: false,
            requiresAuth: true,
            layout: "auth", // specify layout for route here, we require an authorized layout for this route
        },
        props: false,

        component: () =>
            import("@components/application/views/ApplicationEdit.vue"),
    },
];
