import Vue from "vue";
import VueRouter from "vue-router";
import goTo from "vuetify/es5/services/goto";
import { routes } from "./routes";
import store from "@/store";
import utils from "@/utils";
import { authorize as can } from "@/mixins/permissions";

Vue.use(VueRouter);

const authenticate = async (to) => {
    let nextRoute = {};
    let authCheck = {};

    if (!store.getters["Users/loggedIn"]) {
        authCheck = await store.dispatch("Users/checkAuthenticated");
        const { status } = authCheck;
        if (status !== "Authenticated") {
            nextRoute = {
                name: "Login",
                params: { returnUrl: utils.returnUrl(to.fullPath) },
            };
        }
    }
    return { nextRoute, authCheck };
};

// eslint-disable-next-line no-unused-vars
const authorize = async (permission, authCheck) => {
    // we'll either have an authorized user from the initial load of the SPA or we will  have an auth checked user from authenticate
    let authUser =
        Object.keys(store.getters["Users/authUser"]).length === 0
            ? authCheck
            : store.getters["Users/authUser"];
    return can(authUser, permission) ? {} : { name: "Unauthorized" };
};

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0;

        if (to.hash) {
            scrollTo = to.hash;
        } else if (savedPosition) {
            scrollTo = savedPosition.y;
        }

        return goTo(scrollTo);
    },
    routes,
});
router.beforeEach(async (to, from, next) => {
    const {
        // eslint-disable-next-line no-unused-vars
        meta: { requiresAuth = false, permission = "" },
    } = to;

    let nextRoute = {};
    let authCheck = {};

    // First authenticate, no need to authorize if we are not authenticated
    if (requiresAuth) {
        ({ nextRoute, authCheck } = await authenticate(to));
    }
    if (permission.length > 0 && Object.keys(nextRoute).length === 0) {
        nextRoute = authorize(permission, authCheck);
    }
    Object.keys(nextRoute).length > 0 ? next(nextRoute) : next();
});

router.beforeResolve((_to, _from, next) => {
    next();
});
router.afterEach(() => {});
export default router;
