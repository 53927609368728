import uuid from "uuid";

export default {
    maxRentalAddressCount: 4,
    /**
     * Fake Address used for "No Address"
     */
    fakeAddress: {
        address: "123 Anywhere Street Unit A, Nowhere, OR 99999",
        street: "123 Anywhere Street",
        unit: "A",
        city: "Nowhere",
        state: "OR",
        zip: "99999",
    },
    /***
     * Sub sections for the Apply Step. We'll enable the section based on the keys in the applicant data
     */
    applySubSections: [
        {
            slug: "personal-info",
            hasEntities: false,
            enabled: true,
            valid: true,
            errors: [],
            component: "personal-info",
            attribute: "profile",
        },
        {
            slug: "rental-history",
            hasEntities: true,
            enabled: true,
            valid: true,
            errors: [],
            component: "rental-history",
            attribute: "addresses",
        },
        {
            slug: "employment-history",
            hasEntities: true,
            enabled: true,
            valid: true,
            errors: [],
            component: "employment-history",
            attribute: "employers",
        },
        {
            slug: "other-incomes",
            hasEntities: true,
            enabled: true,
            valid: true,
            errors: [],
            component: "applicant-other-incomes",
            attribute: "other_incomes",
        },
        {
            slug: "dependents",
            hasEntities: true,
            enabled: true,
            valid: true,
            errors: [],
            component: "applicant-dependents",
            attribute: "dependents",
        },
        {
            slug: "pets",
            hasEntities: true,
            enabled: true,
            valid: true,
            errors: [],
            component: "applicant-pets",
            attribute: "pets",
        },
        {
            slug: "vehicles",
            hasEntities: true,
            enabled: true,
            valid: true,
            errors: [],
            component: "applicant-vehicles",
            attribute: "vehicles",
        },
        {
            slug: "emergency-contacts",
            hasEntities: true,
            enabled: true,
            valid: true,
            errors: [],
            component: "applicant-emergency-contacts",
            attribute: "emergency_contacts",
        },
        {
            slug: "disclosures",
            hasEntities: true,
            enabled: true,
            valid: true,
            errors: [],
            component: "applicant-disclosures",
            attribute: "disclosures",
        },
    ],

    setupSubSections: [
        {
            slug: "property-details",
            enabled: true, // enabled property only initially
            complete: true,
            component: "property-details",
        },
        {
            slug: "applicant-type",
            complete: false,
            enabled: false,
            component: "applicant-type",
        },
        {
            slug: "cosigner-alert",
            complete: false,
            enabled: false,
            component: "cosigner-alert",
        },
        {
            slug: "applicant-info",
            complete: false,
            enabled: false,
            component: "applicant-info",
        },
        {
            slug: "verify-screening",
            complete: false,
            enabled: false,
            component: "verify-screening",
        },
        {
            slug: "rental-criteria",
            complete: false,
            enabled: false,
            component: "rental-criteria",
        },
        {
            slug: "mobility-rental-history",
            complete: false,
            enabled: false,
            component: "mobility-rental-history",
        },
        {
            slug: "employment-info",
            complete: false,
            enabled: false,
            component: "employment-info",
        },
        {
            slug: "upload-files",
            complete: false,
            enabled: false,
            component: "upload-files",
        },
    ],
    nonApplicantSettings: {
        delay: 500, // artificial delay for non applicant in ms
        minApplicants: 2, // how many applicants to require, minimum should be 2 unless debugging, set to 1
        disableApplicantStatusCheck: false, // only disable during debug
        otherPeopleViewingMin: 2,
    },

    pagination: {
        perPage: 50,
        perPageOptions: [10, 25, 50, 100, 200],
    },
    listingMenuItemActions: [
        {
            id: uuid.v4(),
            slug: "edit-application",
            icon: "mdi-clipboard-text",
            title: "Edit Application",
            sources: ["Online", "Portal"],
            subitems: [],
        },
        {
            id: uuid.v4(),
            icon: "mdi-account-plus",
            title: "Add Applicant",
            open: false,
            sources: ["Online", "Portal"],
            subitems: [
                {
                    title: "Applicant",
                    id: uuid.v4(),
                    slug: "add-applicant",
                    icon: "mdi-clipboard-account",
                },
                {
                    id: uuid.v4(),
                    slug: "add-cosigner",
                    title: "Cosigner",
                    icon: "mdi-draw",
                },
            ],
        },
        {
            id: uuid.v4(),
            title: "Delete Application",
            slug: "delete-application",
            icon: "mdi-delete",
            sources: ["Online", "Portal", "Tenant Tech"],
            subitems: [],
        },
        {
            id: uuid.v4(),
            title: "Copy Application",
            slug: "copy-application",
            icon: "mdi-content-copy",
            sources: ["Online", "Portal", "Tenant Tech"],
            subitems: [],
        },
        {
            id: uuid.v4(),
            title: "Resubmit Application",
            slug: "submit-application",
            icon: "mdi-clipboard-play",
            sources: ["Online", "Portal", "Tenant Tech"],
            subitems: [],
        },
        {
            id: uuid.v4(),
            title: "Complete Application",
            icon: "mdi-clipboard-check",
            sources: ["Online", "Portal"],
            open: false,
            subitems: [
                {
                    id: uuid.v4(),
                    title: "As Applicant",
                    slug: "complete-as-applicant",
                    icon: "mdi-clipboard-account",
                },
                {
                    id: uuid.v4(),
                    title: "As Cosigner",
                    slug: "complete-as-cosigner",
                    icon: "mdi-draw",
                },
                {
                    id: uuid.v4(),
                    title: "As Non-Applicant",
                    slug: "complete-as-non-applicant",
                    icon: "mdi-account-off",
                },
            ],
        },
    ],
    pcaDisclosures: [
        {
            link:
                "https://beta.portland.gov/sites/default/files/2020-01/notice-30.01.086.c.3.b-modification-or-accommodation.pdf",
            slug: "modification-or-accommodation",
        },
        {
            link:
                "https://beta.portland.gov/sites/default/files/2020-01/notice-30.01.086.c.3.c-application-and-screening-rights-and-responsibilities.pdf",
            slug: "rights-and-responsibilities",
        },
    ],
    maxUploadSize: 10000000,
};
