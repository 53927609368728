/**
 * Module
 */
import api from "../api";

const state = {
    // create the default application
    applicant: {}, // Current Applicant
    applications: [], // Paginated list of applicants
    pagination: {}, // Pagination meta data
    payment: {}, // Applicant Payment
    loading: false, // Controls visual loaders for Applicant data
    dataLoading: false,
    states: [],
    paymentConfig: {},
    testApplicants: {}, // keyed by fico score range object
};

const getters = {
    states: (state) => state.states,
    applicant: (state) => state.applicant,
    applications: (state) => state.applications,
    pagination: (state) => state.pagination,
    payment: (state) => state.payment,
    loading: (state) => state.loading,
    dataLoading: (state) => state.dataLoading, // content data loader, shouldn't refresh the entire view, only the loading data component
    testApplicants: (state) => state.testApplicants,
    paymentConfig: (state) => state.paymentConfig,
};

const actions = {
    async getStates({ commit }) {
        try {
            let data = await api.getStates();
            commit("SET_STATES", data);
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
        }
    },
    /**
     * Fills an existing applicant with mock data
     * @param {} param0
     */
    async fillApplicant({ commit }, testapplicant_id) {
        try {
            commit("SET_LOADING", true);
            let applicant = await api.fillApplicant(
                state.applicant.id,
                state.applicant.application.id,
                testapplicant_id
            );
            commit("SET_APPLICANT", applicant);
            commit("SET_LOADING", false);
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
            commit("SET_LOADING", false);
        }
    },
    /**
     *
     * @param {*} commit - store function to mutate payload
     * @param {Object} paginate - data object setting per page attribute and current page
     */
    async getApplications({ commit }, paginate) {
        try {
            commit("SET_LOADING", true);
            let data = await api.getApplications(
                paginate.page,
                paginate.perPage
            );
            commit("SET_APPLICATIONS", data.data);
            commit("SET_PAGINATION", data.meta.pagination);
            commit("SET_LOADING", false);
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
            commit("SET_LOADING", false);
        }
    },
    /**
     * Search Applications by Query Term
     *
     * @param {*} commit - store function to mutate payload
     * @param {string} term - query term to search applications
     */
    async searchApplications({ commit }, term) {
        try {
            commit("SET_LOADING", true);
            let data = await api.searchApplications(term);
            commit("SET_APPLICATIONS", data.data);
            // We don't paginate search results
            commit("SET_PAGINATION", {});
            commit("SET_LOADING", false);
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
            commit("SET_LOADING", false);
        }
    },

    async getApplicant({ commit }, { application_id, applicant_id }) {
        try {
            commit("SET_DATA_LOADING", true);
            let applicant = await api.getApplicant(
                application_id,
                applicant_id
            );
            commit("SET_APPLICANT", applicant);
            commit("SET_DATA_LOADING", false);
        } catch (error) {
            // TODO: Handle expired link and invalid link without an error alert
            commit("SET_ERROR", error, { root: true });
            commit("SET_DATA_LOADING", false);
        }
    },

    /**
     * Finds an applicant by UUID validating the input signature and payload
     *
     * @param {*} param0
     * @param {*} data // structure of applicant_id, signature, email, applicant_id
     */
    async findApplicant({ commit }, data) {
        try {
            commit("SET_LOADING", true);
            let applicant = await api.findApplicant(data);
            commit("SET_APPLICANT", applicant);
            commit("SET_LOADING", false);
        } catch (error) {
            // TODO: Handle expired link and invalid link without an error alert
            commit("SET_ERROR", error, { root: true });
            commit("SET_LOADING", false);
        }
    },
    /**
     * Searches the portal orders database for matching orders
     *
     * @param {Object} param
     * @param {*} data
     */
    async searchHistory({ commit, dispatch }, data) {
        try {
            commit("SET_DATA_LOADING", true);
            let result = await api.searchHistory(data);
            await dispatch(
                "updateApplicant",
                Object.assign(
                    {},
                    {
                        authorizations: Object.assign(
                            state.applicant.authorizations,
                            {
                                sb_484_verified: true,
                                sb_484_passes: result.data.length === 0,
                            }
                        ),
                        profile: Object.assign(state.applicant.profile, {
                            ssn: data.ssn,
                            last_name: data.last_name,
                            birthdate: data.birthdate,
                        }),
                    }
                )
            );
            commit("SET_DATA_LOADING", false);
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
            commit("SET_DATA_LOADING", false);
        }
    },
    /**
     * Updates the applicant with the input attributes
     * @param {*} param0
     * @param {*} data
     */
    async updateApplicant({ commit }, data) {
        try {
            commit("SET_DATA_LOADING", true);
            let applicant = await api.updateApplicant(
                state.applicant.id,
                state.applicant.application.id,
                data
            );
            commit("SET_APPLICANT", applicant);
            // Just use the data loader to not cause a wholesale refresh of the view
            commit("SET_DATA_LOADING", false);
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
            commit("SET_DATA_LOADING", false);
        }
    },

    /**
     * Updates the applicant with the input attributes
     * @param {*} param0
     * @param {*} data
     */
    async submitApplicant({ commit }) {
        try {
            commit("SET_DATA_LOADING", true);
            await api.submitApplicant(
                state.applicant.id,
                state.applicant.application.id
            );
            // We don't set the data loading to false here because we'll wait for the pusher event callback
            // We should receive a submitApplicantEvent complete or error
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
            commit("SET_DATA_LOADING", false);
        }
    },

    /**
     * Event called from Pusher when the applicant has been submitted
     *
     * @param {} param0
     * @param {*} applicant
     */
    async submitApplicantComplete({ commit }, applicant_id) {
        console.log(applicant_id);
        if (applicant_id === state.applicant.applicant_id) {
            commit("SET_APPLICANT_COMPLETE");
            commit("SET_DATA_LOADING", false);
        }
    },

    /**
     * Event called from Pusher when the applicant has been submitted
     *
     * @param {} param0
     * @param {*} applicant
     */
    async submitApplicantError({ commit }, error) {
        console.log(error);
        if (error.applicant_id === state.applicant.applicant_id) {
            commit("SET_ERROR", error.message, { root: true });
            commit("SET_DATA_LOADING", false);
        }
    },

    /**
     * Updates the applicant without reloading the applicant data onto the state. This is a behind the scene udpate
     * @param {*} param0
     * @param {*} data
     */
    async updateWithoutReload({ commit }, data) {
        try {
            await api.updateApplicant(
                state.applicant.id,
                state.applicant.application.id,
                data
            );
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
        }
    },
    /**
     * Get an object of keyed fico score range test applicants
     * @param {*} param0
     */
    async getTestApplicants({ commit }) {
        try {
            commit("SET_DATA_LOADING", true);
            const applicants = await api.getTestApplicants();
            commit("SET_DATA_LOADING", false);
            commit("SET_TEST_APPLICANTS", applicants);
        } catch (error) {
            commit("SET_DATA_LOADING", false);
            commit("SET_ERROR", error, { root: true });
        }
    },
    /**
     * Get the payment configuration for the payment processor
     * @param {*} param0
     */
    async getPaymentConfig({ commit }) {
        try {
            commit("SET_DATA_LOADING", true);
            const config = await api.getPaymentConfig(state.applicant.id);
            commit("SET_PAYMENT_CONFIG", config);
            commit("SET_DATA_LOADING", false);
        } catch (error) {
            commit("SET_DATA_LOADING", false);
            commit("SET_ERROR", error, { root: true });
        }
    },
    async chargePayment({ commit }, charge) {
        try {
            commit("SET_DATA_LOADING", true);
            const payment = await api.chargePayment(state.applicant.id, charge);
            commit("SET_PAYMENT", payment);
            commit("SET_DATA_LOADING", false);
        } catch (error) {
            commit("SET_DATA_LOADING", false);
            commit("SET_ERROR", error, { root: true });
        }
    },
};

const mutations = {
    SET_APPLICATIONS: (state, payload) => {
        state.applications = payload;
    },
    SET_PAGINATION: (state, payload) => {
        state.pagination = payload;
    },
    SET_LOADING: (state, payload) => {
        state.loading = payload;
    },
    SET_DATA_LOADING: (state, payload) => {
        state.dataLoading = payload;
    },
    SET_APPLICANT: (state, payload) => {
        state.applicant = payload;
    },
    SET_APPLICANT_COMPLETE: (state) => {
        state.applicant.status = "Complete";
    },
    SET_STATES: (state, payload) => {
        state.states = payload;
    },
    SET_TEST_APPLICANTS: (state, payload) => {
        state.testApplicants = payload;
    },
    SET_PAYMENT_CONFIG: (state, payload) => {
        state.paymentConfig = payload;
    },
    SET_PAYMENT: (state, payload) => {
        state.payment = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
