var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "hidden-md-and-up", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-toolbar",
                {
                  attrs: {
                    dense: "",
                    height: "30px",
                    color: "primary",
                    dark: ""
                  }
                },
                [
                  _c("v-toolbar-title", {
                    staticClass: "subtitle-2 text-uppercase",
                    domProps: { innerHTML: _vm._s(_vm.sectionTitle) }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "hidden-sm-and-down pr-2", attrs: { md: "auto" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "ml-4 pr-3 pt-4",
                  attrs: {
                    "elevation-0": "",
                    color: "secondary",
                    height: "80px",
                    width: "200px",
                    dark: ""
                  }
                },
                [
                  _c("p", {
                    staticClass:
                      "font-weight-bold text-right text-uppercase subheading",
                    domProps: { innerHTML: _vm._s(_vm.sectionTitle) }
                  })
                ]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "hidden-sm-and-down", attrs: { md: "auto" } },
            [
              _c("v-divider", {
                staticClass: "mx-3 hidden-sm-and-down",
                attrs: { vertical: "" }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "px-2 pb-0",
              attrs: { cols: "12", md: _vm.wideFirstCol ? 7 : 5 }
            },
            [_vm._t("col-1")],
            2
          ),
          _c("v-col", { staticClass: "px-2" }, [_vm._t("col-2")], 2),
          _c(
            "v-col",
            { staticClass: "hidden-sm-and-down", attrs: { cols: "12" } },
            [_c("v-divider", { staticClass: "ml-1" })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }