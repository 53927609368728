import { CookieStorage } from "cookie-storage";
import dayjs from "dayjs";

export default {
    getItem(key) {
        const storage = new CookieStorage();
        return storage.getItem(key) === null
            ? {}
            : JSON.parse(storage.getItem(key));
    },
    // eslint-disable-next-line no-unused-vars
    setItem(key, value, expiresMinutes = 120) {
        // eslint-disable-next-line no-unused-vars
        const domain = window.location.hostname
            .split(".")
            .slice(-2)
            .join(".");

        const expires = dayjs()
            .add(expiresMinutes, "m")
            .toDate();

        const params = {
            path: "/",
            domain: `.${domain}`,
            expires,
            sameSite: "Lax",
        };
        // eslint-disable-next-line no-unused-vars
        const storage = new CookieStorage(params);
        storage.setItem(key, JSON.stringify(value));
    },
    removeItem(key) {
        const storage = new CookieStorage();
        storage.removeItem(key);
    },
};
