import Vue from "vue";
// Vuetify dynamic loader can't detect components when loaded from PSI Component Library. Import the specific components needed for the PSI Component Lib
import Vuetify, {
    VBtn,
    VCard,
    VCardTitle,
    VCardText,
    VCardActions,
    VSpacer,
    VIcon,
    VList,
    VListItem,
    VListItemIcon,
    VListItemTitle,
    VListItemAction,
    VListItemContent,
    VListItemSubtitle,
    VMenu,
    VDivider,
    VProgressCircular,
    VProgressLinear,
    VCol,
    VRow,
    VContainer,
    VMain,
    VChip,
    VSelect,
    VBadge,
    VToolbar,
    VToolbarTitle,
    VBreadcrumbs,
    VBreadcrumbsItem,
    VSnackbar,
    VSwitch,
    VSimpleTable,
    VTooltip,
    VDialog,
    VTextField,
    VNavigationDrawer,
    VAvatar,
    VListGroup,
    VAutocomplete,
    VForm,
    VListItemGroup,
} from "vuetify/lib";
import { Scroll, Ripple } from "vuetify/lib/directives";

import settings from "@config/app.settings";

Vue.use(Vuetify, {
    components: {
        VBtn,
        VCard,
        VCardText,
        VCardTitle,
        VSpacer,
        VIcon,
        VList,
        VListItem,
        VListItemIcon,
        VListItemTitle,
        VListItemContent,
        VListItemAction,
        VListItemSubtitle,
        VMenu,
        VDivider,
        VProgressCircular,
        VProgressLinear,
        VCol,
        VRow,
        VContainer,
        VMain,
        VChip,
        VCardActions,
        VSelect,
        VBadge,
        VToolbar,
        VToolbarTitle,
        VBreadcrumbs,
        VBreadcrumbsItem,
        VSnackbar,
        VSwitch,
        VSimpleTable,
        VTooltip,
        VDialog,
        VTextField,
        VNavigationDrawer,
        VAvatar,
        VListGroup,
        VAutocomplete,
        VForm,
        VListItemGroup,
    },
    directives: {
        Scroll,
        Ripple,
    },
});
const theme = settings.colorTheme.primary;

export default new Vuetify({
    theme: {
        options: {
            /* Enabling customProperties will also generate a css variable for each theme color, which you can then use in your components' <style> blocks.
               customProperties allows access to theme colors in <style> blocks of Vue Single file templates via 
               https://vuetifyjs.com/en/customization/theme/

               <style scoped>
                .something {
                    color: var(--v-primary-base);
                    background-color: var(--v-accent-lighten2);
                }
                </style>
            */
            customProperties: true,
            variations: true,
        },
        themes: {
            dark: theme,
            light: theme,
        },
        dark: false, // If you want to set dark theme then dark:true else set to false
    },
    rtl: false, // If you want to set rtl theme then rtl:true else set to false
});
