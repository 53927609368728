import httpClient from "@api/client";

export default {
    async getCompanies() {
        let { data } = await httpClient.get("/companies");
        return data.data;
    },
    async getCompany(id) {
        let { data } = await httpClient.get(`/companies/${id}`);
        return data.data;
    },
    async getProperties() {
        let { data } = await httpClient.get("/properties");
        return data.data;
    },
    async getProperty(id) {
        let { data } = await httpClient.get(`/properties/${id}`);
        return data.data;
    },
};
