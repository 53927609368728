export default {
    messageTimeout: 3000,
    animation: "slideInRight",
    appLogoIcon:
        "https://pacific-screening.imgix.net/logos/psi-logo-mark-md.png?width=35&q=70",
    appLogoText:
        "https://pacific-screening.imgix.net/logos/psi-logo-text-alt-lg.png?width=167&q=90",
    companyBackgroundImg:
        "https://pacific-screening-static.s3.amazonaws.com/images/company-background-sm.png",
    companyName: "Pacific Screening, Inc",
    layoutColors: {
        containerBackground: "primary lighten-2",
        sideBarBackground: "alternate3 darken-5",
        sideBarItemText: "white--text",
        sideBarGroupText: "white--text ",
        activeSideBarItemText: "primary--text text--lighten-3",
        activeGroupItemClass: "primary lighten-1 primary--text text--lighten-4",
        footerBackground: "primary darken-2",
    },
    colorTheme: {
        primary: {
            primary: "#4f6f78",
            secondary: "#518573",
            accent: "#57748F",
            error: "#BF360C",
            info: "#6EB2C4",
            success: "#84D9BD",
            warning: "#FFC107",
            alternate1: "#57748f",
            alternate2: "#515e85",
            alternate3: "#4f6f78",
            alternate4: "#578f8c",
            alternate5: "#518573",
            //      BandwidthChart: BandwidthChart,
        },
        secondary: {
            primary: "#4f6f78",
            secondary: "#518573",
            accent: "#57748F",
            error: "#BF360C",
            info: "#186DB0",
            success: "#4CAF50",
            warning: "#FFC107",
            alternate1: "#57748f",
            alternate2: "#515e85",
            alternate3: "#4f6f78",
            alternate4: "#578f8c",
            alternate5: "#518573",
        },
    },
};
