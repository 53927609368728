// import the api endpoints
import api from "../api";
import repository from "../repository";
import router from "@/router";
import utils from "@/utils";
import cookie from "../lib/cookie";

const SESSION_AUTH_KEY = "PSI_SESSION_AUTH";

const initialState = () => ({
    authUser: {},
    user: {},
    users: [],
    menus: {},
    loading: false,
    loggedIn: false,
});

const state = initialState();

const getters = {
    authUser: (state) => state.authUser,
    user: (state) => state.user,
    users: (state) => state.users,
    menus: (state) => state.menus,
    loading: (state) => state.loading,
    loggedIn: (state) => state.loggedIn,
};

const actions = {
    reset({ commit }) {
        commit("RESET");
    },
    async logout({ commit }) {
        try {
            await api.logout();
            cookie.removeItem(SESSION_AUTH_KEY);
            // trying to get authenticated user after a logout forces a redirect
            router.push({
                name: "Login",
                params: { returnUrl: utils.returnUrl() },
            });
        } catch (error) {
            // ignore error
            commit("SET_LOADING", false, { root: true });
        }
    },

    // eslint-disable-next-line no-unused-vars
    async sessionExpired({ dispatch }) {
        router.push({
            name: "Login",
            params: { returnUrl: utils.returnUrl() },
        });
    },
    async checkAuthenticated() {
        try {
            // see if we are authenticated from the cookie storage
            let result = cookie.getItem(SESSION_AUTH_KEY);
            if (Object.keys(result).length === 0) {
                result = await api.authCheck();
                // store authenticated
                if (result.status === "Authenticated") {
                    cookie.setItem(SESSION_AUTH_KEY, result, result.expires);
                }
            }
            return result;
        } catch (error) {
            return {
                status: "Unauthenticated",
            };
        }
    },
    async getAuthenticatedUser({ commit }) {
        try {
            commit("SET_LOADING", true, { root: true });
            // Adding a repository in the front here allows for local storage cache
            const user = await repository.getAuthenticatedUser();
            const menus = await repository.getUserMenus(user["cacheHit"]);
            commit("SET_MENUS", menus);
            commit("SET_AUTH_USER", user);
            commit("SET_LOGGED_IN");
            commit("SET_LOADING", false, { root: true });
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
            commit("SET_LOADING", false, { root: true });
        }
    },
    async getUser({ commit }, id) {
        try {
            //commit("SET_LOADING", true, { root: true });
            const user = await api.getUser(id);

            commit("SET_USER", user);
            commit("SET_LOADING", false, { root: true });
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
            commit("SET_LOADING", false, { root: true });
        }
    },
    async getUsers({ commit }) {
        try {
            commit("SET_LOADING", true, { root: true });
            const users = await api.getUsers();
            commit("SET_USERS", users);
            commit("SET_LOADING", false, { root: true });
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
            commit("SET_LOADING", false, { root: true });
        }
    },
    async searchUsers({ commit }, params) {
        try {
            commit("SET_LOADING");
            const users =
                Object.keys(params).length > 0
                    ? await api.searchUsers(params)
                    : [];
            commit("SET_USERS", users);
            commit("SET_LOADING", false);
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
            commit("SET_LOADING", false);
        }
    },
    async impersonateUser({ commit }, params) {
        try {
            commit("SET_LOADING");
            const { user_id, impersonate } = params;
            let data = await api.impersonateUser(user_id, impersonate);
            commit("SET_LOADING", false);
            return data;
        } catch (error) {
            commit("SET_ERROR", error, { root: true });
            commit("SET_LOADING", false);
        }
    },
};

const mutations = {
    RESET: (state) => {
        const newState = initialState();
        Object.keys(newState).forEach((key) => {
            state[key] = newState[key];
        });
    },
    SET_USERS: (state, payload) => {
        state.users = payload;
    },
    SET_USER: (state, payload) => {
        state.user = payload;
    },
    SET_AUTH_USER: (state, payload) => {
        state.authUser = payload;
        state.loggedIn = Object.keys(state.user).length > 0;
    },
    SET_MENUS: (state, payload) => {
        state.menus = payload;
    },
    SET_LOGGED_OUT: (state, payload = false) => {
        state.loggedIn = payload;
    },
    SET_LOGGED_IN: (state, payload = true) => {
        state.loggedIn = payload;
    },
    SET_LOADING: (state, payload) => {
        state.loading = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
