import axios from "axios";
import store from "@/store";

const httpClient = axios.create({
    withCredentials: process.env.VUE_APP_API_CREDENTIALS === "enable",
    baseURL: process.env.VUE_APP_BASE_URL, // Laravel API URL
    headers: {
        "Content-Type": "application/json",
    },
});

/**
 * Parse the incoming data for additional error strings enumerating keys looking for string vars
 * @param {*} message   // prepend return with this messag
 * @param {*} data      // object to extract error strings from keys
 * @returns string      // result error message
 */
const parseErrors = (message, data) => {
    let errors = [];
    if (typeof data === "object") {
        Object.keys(data).forEach((key) => {
            if (typeof data[key] === "string") {
                errors.push(data[key]);
            }
        });
    }
    if (typeof data === "string") {
        message += ` ${data}`;
    }
    // only unique errors
    return `${message} ${[...new Set(errors)].join(" ")}`;
};

// We will redirect to login if we get an authorization failure
httpClient.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status) {
            let errorMessage = "";
            switch (error.response.status) {
                case 401:
                    // only a session timeout if not trying to check logged in state
                    if (error.response.config.url !== "/user/authcheck") {
                        store.dispatch("Users/sessionExpired");
                    }
                    break;
                default:
                    errorMessage = parseErrors(
                        error.message,
                        error.response.data.message
                    );
                    // Broadcast error to our snackbar
                    store.dispatch("Snackbar/showMessage", {
                        content: errorMessage,
                        color: "error",
                    });
                    if (store._actions["error"]) {
                        console.log("Broadcasting error", errorMessage);
                        // Broadcast to root
                        store.dispatch("error", errorMessage);
                    }
            }
        }
        return Promise.reject(error);
    }
);
export default httpClient;
