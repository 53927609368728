<template>
    <v-container class="ma-0 pa-0">
        <v-row class="my-0">
            <v-col cols="12" :md="threecols ? 4 : 6" class="my-0 py-0">
                <slot name="col-1"></slot>
            </v-col>
            <v-col cols="12" :md="threecols ? 4 : 6" class="my-0 py-0">
                <slot name="col-2"></slot>
            </v-col>
            <v-col
                v-if="threecols"
                cols="12"
                :md="threecols ? 4 : 6"
                class="my-0 py-0"
            >
                <slot name="col-3" class="px-2"></slot>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "form-element-group",
    props: {
        threecols: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
