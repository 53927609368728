<template>
    <vue-trix v-model="content" placeholder="Enter note"></vue-trix>
</template>
<script>
import VueTrix from "vue-trix";

export default {
    name: "psi-form-trix-field",
    components: {
        VueTrix,
    },
    props: {
        value: {
            type: String,
            required: false,
            default: "",
        },
        placeholder: {
            type: String,
            required: false,
            default: "",
        },
    },
    data() {
        return {
            content: this.value,
        };
    },
    computed: {},
    watch: {
        content() {
            this.$emit("input", this.content);
        },
    },
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style>
.trix-button--icon-attach {
    display: none !important;
}
.trix-button-group--file-tools {
    display: none !important;
}
.trix-content {
    min-height: 12em !important;
}
</style>