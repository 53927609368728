var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form-element",
    { attrs: { label: _vm.label, required: _vm.required } },
    [
      _vm.mask.length > 0
        ? _c(
            "v-text-field",
            _vm._b(
              {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: _vm.mask,
                    expression: "mask"
                  }
                ],
                class: [
                  { "mt-n4": _vm.$vuetify.breakpoint.smAndUp && _vm.marginTop },
                  { "mt-2": _vm.$vuetify.breakpoint.smAndUp && !_vm.marginTop },
                  {
                    "mt-n3": _vm.$vuetify.breakpoint.smAndDown && _vm.marginTop
                  },
                  {
                    "mt-1": _vm.$vuetify.breakpoint.smAndDown && !_vm.marginTop
                  }
                ],
                attrs: {
                  "prepend-inner-icon": _vm.icon,
                  placeholder: _vm.placeholder,
                  hint: _vm.hint,
                  "validate-on-blur": true,
                  "persistent-hint": _vm.persistentHint,
                  rules: _vm.rules,
                  label: _vm.label,
                  disabled: _vm.disabled,
                  dusk: _vm.name,
                  required: _vm.required
                },
                on: {
                  input: function($event) {
                    return _vm.$emit("input", _vm.model)
                  },
                  blur: function($event) {
                    return _vm.$emit("blur", _vm.model)
                  },
                  change: function($event) {
                    return _vm.$emit("change", _vm.model)
                  }
                },
                model: {
                  value: _vm.model,
                  callback: function($$v) {
                    _vm.model = $$v
                  },
                  expression: "model"
                }
              },
              "v-text-field",
              _vm.baseStyle,
              false
            )
          )
        : _c(
            "v-text-field",
            _vm._b(
              {
                class: [
                  { "mt-n4": _vm.$vuetify.breakpoint.smAndUp && _vm.marginTop },
                  { "mt-2": _vm.$vuetify.breakpoint.smAndUp && !_vm.marginTop },
                  {
                    "mt-n3": _vm.$vuetify.breakpoint.smAndDown && _vm.marginTop
                  },
                  {
                    "mt-1": _vm.$vuetify.breakpoint.smAndDown && !_vm.marginTop
                  }
                ],
                attrs: {
                  "prepend-inner-icon": _vm.icon,
                  placeholder: _vm.placeholder,
                  hint: _vm.hint,
                  "persistent-hint": _vm.persistentHint,
                  rules: _vm.rules,
                  label: _vm.label,
                  disabled: _vm.disabled,
                  dusk: _vm.name,
                  required: _vm.required
                },
                on: {
                  input: function($event) {
                    return _vm.$emit("input", _vm.model)
                  },
                  blur: function($event) {
                    return _vm.$emit("blur", _vm.model)
                  },
                  change: function($event) {
                    return _vm.$emit("change", _vm.model)
                  }
                },
                model: {
                  value: _vm.model,
                  callback: function($$v) {
                    _vm.model = $$v
                  },
                  expression: "model"
                }
              },
              "v-text-field",
              _vm.baseStyle,
              false
            )
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }