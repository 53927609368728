<template>
    <!-- @blur="date = parseDate(dateFormatted)" 
    -->
    <form-element :label="label" :required="required">
        <v-flex xs12>
            <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                :nudge-bottom="10"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                max-width="290px"
                min-width="290px"
            >
                <template v-slot:activator="{ on }">
                    <v-text-field
                        v-model="dateFormatted"
                        :label="label"
                        :placeholder="`${label} in MM/DD/YYYY`"
                        mask="##/##/####"
                        hint="Enter Date in MM/DD/YYYY"
                        :rules="[verify]"
                        persistent-hint
                        solo
                        height="40"
                        return-masked-value
                        prepend-inner-icon="event"
                        @input="$emit('input', formatDate(date))"
                        v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="date"
                    no-title
                    color="primary"
                    @input="menu = false"
                    @change="$emit('input', formatDate(date))"
                ></v-date-picker>
            </v-menu>
        </v-flex>
    </form-element>
</template>
<script>
import FormElement from "./FormElement";

export default {
    name: "form-date-element",
    components: {
        FormElement,
    },
    props: {
        value: {
            type: String,
            required: false,
            default: "",
        },
        label: {
            type: String,
            required: true,
        },
        required: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            dateFormatted: this.formatDate(this.parseDate(this.value)),
            date:
                this.value != ""
                    ? new Date(this.value).toISOString().substr(0, 10)
                    : new Date().toISOString().substr(0, 10),
            menu: false,
        };
    },
    watch: {
        date() {
            this.dateFormatted = this.formatDate(this.date);
        },
    },
    methods: {
        formatDate(date) {
            if (!date || date == "") return null;

            const [year, month, day] = date.split("-");
            return `${month}/${day}/${year}`;
        },
        parseDate(date) {
            if (!date || date == "") return null;

            const [month, day, year] = date.split("/");
            return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
        },
        verify(v) {
            if (v) {
                return (
                    v.length == 0 ||
                    /^((((0[13578])|([13578])|(1[02]))[/](([1-9])|([0-2][0-9])|(3[01])))|(((0[469])|([469])|(11))[/](([1-9])|([0-2][0-9])|(30)))|((2|02)[/](([1-9])|([0-2][0-9]))))[/]\d{4}$|^\d{4}$/.test(
                        v
                    ) ||
                    "Date is not valid"
                );
            } else {
                return this.required
                    ? !!v || `${this.label} is Required`
                    : true;
            }
        },
    },
};
</script>

<style scoped>
</style>