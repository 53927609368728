import httpClient from "@api/client";

import { transformMenus } from "./transform";
import { config } from "@config";

// if we are prototyping, use the user ID = 3 which
const API_GET_AUTHENTICATED_USER = process.env.VUE_APP_PROTOTYPING
    ? `/users/${config("prototype.user_id")}`
    : "/user";
// use fully qualified to bypass prototyping

const API_LOGOUT = "/user/logout";
const API_GET_USERS = "/users";
const API_SEARCH_USERS = "/users";
const API_IMPERSONATE_USER = "/user/impersonate";
const API_GET_USER_MENUS = "/menus";
const API_AUTHCHECK_USER = "/user/authcheck";

export default {
    async authCheck() {
        let res = await httpClient.get(API_AUTHCHECK_USER);
        return res.data;
    },
    async getAuthenticatedUser() {
        let res = await httpClient.get(API_GET_AUTHENTICATED_USER);
        return res.data;
    },
    async getUsers(params = {}) {
        let res = await httpClient.get(API_GET_USERS, {
            params,
        });
        return res.data.data;
    },

    async searchUsers(params = {}) {
        const filters = {
            skip_presenter: true,
            skip_appends: true,
            minimal: true,
        };
        let res = await httpClient.get(API_SEARCH_USERS, {
            params: Object.assign(filters, params),
        });
        return res.data.data;
    },

    async impersonateUser(user_id, impersonate) {
        let res = await httpClient.post(API_IMPERSONATE_USER, {
            user_id,
            impersonate,
        });
        return res.data.data;
    },
    async getUser(id) {
        let res = await httpClient.get(`${API_GET_USERS}/${id}`);
        return res.data.data;
    },
    async getUserMenus() {
        let res = await httpClient.get(API_GET_USER_MENUS);
        return transformMenus(res.data);
    },
    async logout() {
        let { data } = await httpClient.post(API_LOGOUT);
        return data;
    },
};
