// import the api endpoints
import api from "../api";
import store from "@/store";

const USER_STORAGE_KEY = "psi-auth-user";
const MENU_STORAGE_KEY = "psi-user-menu";

export default {
    async getAuthenticatedUser() {
        let authCheck = {};
        // Authcheck is a simple fast check of the logged in user without loading additional database relationships
        try {
            authCheck = await store.dispatch("Users/checkAuthenticated");
        } catch (error) {
            // we shouldn't error here because the route requires auth, but if we do, just dispatch an error and return
            store.dispatch(
                "Snackbar/showMessage",
                {
                    content: error,
                    color: "error",
                },
                { root: true }
            );
        }

        const {
            user: { last_logged_in: lastLoggedIn = "", id: userId = 0 },
        } = authCheck;
        // Check local storage
        let $user = localStorage.getItem(USER_STORAGE_KEY)
            ? JSON.parse(localStorage.getItem(USER_STORAGE_KEY))
            : null;

        if (
            $user &&
            $user["last_logged_in"] === lastLoggedIn &&
            $user["id"] === userId
        ) {
            // indicate we loaded from cache so we can pull menus as well
            $user["cacheHit"] = true;
            // We can return from local storage cache
            return $user;
        }
        $user = await api.getAuthenticatedUser();
        // Indicate we didn't load from cache
        $user["cacheHit"] = false;
        localStorage.setItem(USER_STORAGE_KEY, JSON.stringify($user));
        return $user;
    },
    async getUserMenus(fromCache = false) {
        if (fromCache && localStorage.getItem(MENU_STORAGE_KEY) !== null) {
            return JSON.parse(localStorage.getItem(MENU_STORAGE_KEY));
        }
        const userMenus = await api.getUserMenus();
        localStorage.setItem(MENU_STORAGE_KEY, JSON.stringify(userMenus));
        return userMenus;
    },
};
