import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import vuetify from "./plugins/vuetify";
import "@plugins/config";
import "@plugins/utils";

import "@/plugins/vue-uuid";
import "@/plugins/page-transitions";
import "@/plugins/json-viewer";
import "@/plugins/vue-clipboard";

// TODO: REMOVE AFTER INTEGRATING COMPONENT LIBRARY
import "@/plugins/global-components";
import i18n from "@/plugins/vue-18n";
import Vapor from "@/plugins/vapor";

Vue.prototype.$vapor = Vapor;

import PsiComponentLib from "@ronfuller/psi-component-lib";

Vue.use(PsiComponentLib);
import Permissions from "@/mixins/permissions";

// Import Global Access to Trix, Trouble with this package forced a component outside the Psi Component Lib
import PsiFormTrixField from "@components/lib/PsiFormTrixField";
Vue.component("PsiFormTrixField", PsiFormTrixField);
Vue.mixin(Permissions);

Vue.config.productionTip = false;

new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount("#app");
