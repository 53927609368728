import Vue from "vue";

import FormSection from "../components/application/components/Form/FormSection";
import FormElement from "../components/application/components/Form/FormElement";
import FormElementGroup from "../components/application/components/Form/FormElementGroup";
import FormTextField from "../components/application/components/Form/FormTextField";
import FormDateElement from "../components/application/components/Form/FormDateElement";
import FormCurrencyTextField from "../components/application/components/Form/FormCurrencyTextField";

Vue.component("FormSection", FormSection);
Vue.component("FormElementGroup", FormElementGroup);
Vue.component("FormElement", FormElement);
Vue.component("FormTextField", FormTextField);
Vue.component("FormDateElement", FormDateElement);
Vue.component("FormCurrencyTextField", FormCurrencyTextField);
