var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0" },
    [
      _c(
        "v-row",
        { staticClass: "my-0" },
        [
          _c(
            "v-col",
            {
              staticClass: "my-0 py-0",
              attrs: { cols: "12", md: _vm.threecols ? 4 : 6 }
            },
            [_vm._t("col-1")],
            2
          ),
          _c(
            "v-col",
            {
              staticClass: "my-0 py-0",
              attrs: { cols: "12", md: _vm.threecols ? 4 : 6 }
            },
            [_vm._t("col-2")],
            2
          ),
          _vm.threecols
            ? _c(
                "v-col",
                {
                  staticClass: "my-0 py-0",
                  attrs: { cols: "12", md: _vm.threecols ? 4 : 6 }
                },
                [_vm._t("col-3")],
                2
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }