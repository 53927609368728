var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "psi" } },
    [
      _vm.$route.meta.layout
        ? _c(_vm.$route.meta.layout + "-layout", { tag: "component" })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }