var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _vm.items.length === 0
        ? _c(
            "h4",
            { staticClass: "font-weight-regular", class: _vm.labelClass },
            [
              _c("span", [_vm._v(_vm._s(_vm.label))]),
              _c(
                "v-icon",
                {
                  staticClass: "mb-1 ml-1",
                  attrs: { size: "10px", color: "error" }
                },
                [_vm._v(_vm._s(_vm.icon))]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.items.length > 0
        ? _c(
            "div",
            {
              staticClass: "d-flex align-center mb-2",
              staticStyle: { width: "300px" }
            },
            [
              _c("v-select", {
                attrs: {
                  dense: "",
                  "hide-details": "",
                  solo: "",
                  value: _vm.label,
                  items: _vm.items
                },
                on: {
                  change: function($event) {
                    return _vm.$emit("update:label", $event)
                  }
                }
              }),
              _c(
                "v-icon",
                {
                  staticClass: "mb-1 ml-1",
                  attrs: { size: "10px", color: "error" }
                },
                [_vm._v(_vm._s(_vm.icon))]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }