const Pusher = require("pusher-js");
export default function pusher() {
    return (store) => {
        let pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
            cluster: "us3",
        });
        let channel = pusher.subscribe("apply-channel");
        // Event is broadcast after order is created and online application created
        channel.bind("submit-applicant-complete", ({ applicant_id }) => {
            store.dispatch(
                "Applications/submitApplicantComplete",
                applicant_id
            );
        });
        // Event is broadcast after order is created and online application created
        channel.bind("submit-applicant-error", ({ error }) => {
            store.dispatch("Applications/submitApplicantError", error);
        });
    };
}
