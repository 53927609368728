import httpClient from "@api/client";

const API_GET_APPLICATIONS = "/apply/applications";
const API_SEARCH_APPLICATIONS = "/apply/applications/search";
const API_FIND_APPLICANT = "/apply/applicant/find";
const API_VALIDATE_SIGNATURE = "/apply/auth/verify";
const API_SEARCH_HISTORY = "/orders/search/history";
const API_UPDATE_APPLICANT = "/apply/applications";
const API_APPLICATION = "/apply/applications";
const API_CREATE_ENTITY = "/apply/applications";
const API_GET_STATES = "/states";
const API_GET_TEST_APPLICANTS = "/apply/test-applicants";
const API_SIGN_APPLICATION = "/apply/signature";
const API_APPLICANTS = "/apply/applicants";

export default {
    /**
     * Gets the most recent applications paged
     * @param int page - page number to retrieve
     * @param int perPage - number of pages per query
     * @return Object - result of query includes meta and data
     */
    async getApplications(page, perPage) {
        let response = await httpClient.get(
            `${API_GET_APPLICATIONS}?page=${page}&per_page=${perPage}`
        );
        return response.data;
    },

    /**
     * Searches all applications by the query term
     * @param string term
     * @returns array
     */
    async searchApplications(term) {
        let response = await httpClient.get(
            `${API_SEARCH_APPLICATIONS}?term=${term}`
        );
        return response.data;
    },
    /**
     * Finds an applicant from the input signature, email, applicant id and expiration
     * @param object params
     * @returns
     */
    async findApplicant(params) {
        // If an applicant has a valid signature and is found, we will get a response here with an applicant
        let response = await httpClient.post(API_FIND_APPLICANT, params);
        return response.data.data;
    },

    /**
     * Gets an applicant by Applicant UUID
     * @param object params
     * @returns
     */
    async getApplicant(application_id, applicant_id) {
        // If an applicant has a valid signature and is found, we will get a response here with an applicant
        let response = await httpClient.get(
            `${API_GET_APPLICATIONS}/${application_id}/applicants/${applicant_id}`
        );
        return response.data.data;
    },

    /**
     *
     * @param {Object} params
     * @returns Object {'}
     */
    async searchHistory(params) {
        let response = await httpClient.post(API_SEARCH_HISTORY, params);
        return response.data;
    },

    /**
     *
     * @param {*} param0
     * @returns
     */
    async updateApplicant(applicant_id, application_id, data) {
        let response = await httpClient.put(
            `${API_UPDATE_APPLICANT}/${application_id}/applicants/${applicant_id}`,
            data
        );
        return response.data.data;
    },

    /**
     *
     * @param {*} applicant_id
     * @param {*} application_id
     * @returns
     */
    async submitApplicant(applicant_id, application_id) {
        let response = await httpClient.get(
            `${API_UPDATE_APPLICANT}/${application_id}/applicants/${applicant_id}/submit`
        );
        return response.data;
    },

    /**
     *
     * @param {*} applicant_id
     * @param {*} application_id
     * @param {*} testapplicant_id
     * @returns
     */
    async fillApplicant(applicant_id, application_id, testapplicant_id = 0) {
        let url = `${API_APPLICATION}/${application_id}/applicants/${applicant_id}/fill`;
        if (testapplicant_id > 0) {
            url += `?testapplicant_id=${testapplicant_id}`;
        }
        let response = await httpClient.get(url);
        return response.data.data;
    },

    /**
     * Validate a signed payload
     *
     * @param {*} params
     * @returns
     */
    async validateSignature(params) {
        let response = await httpClient.post(API_VALIDATE_SIGNATURE, params);
        return response.data;
    },

    /**
     * Get an array of states for populating state selector in addresses
     * @returns
     */
    async getStates() {
        let response = await httpClient.get(API_GET_STATES);
        return response.data;
    },

    /**
     *
     * @param {*} application_id
     * @param {*} applicant_id
     * @param {*} entity
     * @returns
     */
    async createEntity(application_id, applicant_id, entity) {
        let response = await httpClient.get(
            `${API_CREATE_ENTITY}/${application_id}/applicants/${applicant_id}/create?entity=${entity}`
        );
        return response.data.data;
    },

    /**
     * Retrieves an initialized entity { address, employer } with fake data
     *
     * @param {*} application_id
     * @param {*} applicant_id
     * @param {*} entity
     * @returns
     */
    async makeEntity(application_id, applicant_id, entity) {
        let response = await httpClient.get(
            `${API_CREATE_ENTITY}/${application_id}/applicants/${applicant_id}/make?entity=${entity}`
        );
        return response.data;
    },

    /**
     * Get all test applicants grouped by FICO score range
     * @returns array
     */
    async getTestApplicants() {
        let response = await httpClient.get(API_GET_TEST_APPLICANTS);
        return response.data;
    },

    /**
     * Sign the application with the input image
     * @param {*} applicant_id
     * @param {*} image
     * @returns
     */
    async signApplication(applicant_id, image) {
        let response = await httpClient.put(API_SIGN_APPLICATION, {
            image,
            applicant_id,
        });
        return response.data;
    },

    /**
     * Get the configuration for the payment processor
     *
     * @param {int} applicant_id
     * @returns
     */
    async getPaymentConfig(applicant_id) {
        let response = await httpClient.get(
            `${API_APPLICANTS}/${applicant_id}/payments/config`
        );
        return response.data;
    },

    /**
     * Calls the Payment API to charge the card amount
     *
     * @param {int} applicant_id
     * @param {Object} charge
     * @returns
     */
    async chargePayment(applicant_id, charge) {
        let response = await httpClient.post(
            `${API_APPLICANTS}/${applicant_id}/payments/`,
            charge
        );
        return response.data.data;
    },
};
