import { mapGetters } from 'vuex'

export function authorize(user, permission) {
	return Object.keys(user).length > 0
		? getPermissions(user, permission)
		: false // Admin
}

export function getPermissions(user, permission) {
	return (
		user.permission_names.includes(permission) || // direct match permission
		user.permission_names.includes(`${permission}-any`) || // any match , eg. programs.edit-any is valid for programs.edit
		user.permission_names.includes(`${permission.split('.').shift()}.*`) || //  wildcard permission
		user.role_name === 'Super Admin'
	)
}

export default {
	computed: {
		...mapGetters('Users', ['authUser']),
		isStudent() {
			return this.$_.get(this.authUser, 'role_name', '') === 'Student'
		},
		isInstructor() {
			return this.$_.get(this.authUser, 'role_name', '') === 'Instructor'
		},
		isProgramAdmin() {
			return (
				this.$_.get(this.authUser, 'role_name', '') === 'Program Admin'
			)
		},
		isSuperAdmin() {
			return this.$_.get(this.authUser, 'role_name', '') === 'Super Admin'
		},
	},
	methods: {
		can(permission) {
			return authorize(this.authUser, permission)
		},
		getPermissions,
	},
}
