import get from "lodash.get";
const subdomain = window.location.hostname.split(".")[0];

const addDividers = (items) => {
    let withDividers = [];
    // Prepend a divider if the item has a divider
    items.forEach((item) => {
        if (item.divider) {
            withDividers.push({
                divider: true,
            });
        }
        // don't include the divider element in the menu item, only in a divider element
        delete item.divider;
        withDividers.push(item);
    });
    return withDividers;
};

const getMenuMeta = (item) => {
    let meta = {};

    if (get(item, "is_group", false)) {
        meta["group"] = item.path;
    }
    // Determine if external or internal link based on
    // our subdomain
    if (
        subdomain === get(item, "subdomain", "") ||
        subdomain === "prototypes"
    ) {
        meta["to"] = get(item, "link.to", "");
    } else {
        // don't had href for non-subdomain links
        if (get(item, "subdomain", "").length > 0) {
            meta["href"] = get(item, "link.href", "");
        }
    }
    // enable the menu item unless we are trying to match the subdomain exactly and we are not on that subdomain
    meta["enabled"] =
        get(item, "exact", false) && get(item, "subdomain", "") !== subdomain
            ? false
            : true;
    return meta;
};

const getSubMenu = (items) => {
    return items
        .map((item) => {
            const {
                icon,
                text,
                text: title,
                slug: id,
                meta: { divider = false },
            } = item;
            let subMenu = Object.assign(
                { icon, text, id, divider, title },
                getMenuMeta(item)
            );
            return subMenu;
        })
        .filter((item) => item.enabled);
};

const getHeadingMenu = (items) => {
    return items
        .map((heading) => {
            const {
                icon,
                text,
                text: title,
                slug: id,
                meta: { divider = false },
                menu_items: children = [],
            } = heading;

            let menuHeading = Object.assign(
                { icon, text, id, divider, title, children },
                getMenuMeta(heading)
            );
            menuHeading.children = getSubMenu(menuHeading.children);
            // Handle spacing dividers
            menuHeading.children = addDividers(menuHeading.children);

            return menuHeading;
        })
        .filter((item) => item.enabled);
};
export const transformMenus = (data) => {
    // We'll build up the return menu
    let menus = {};
    try {
        data.forEach((item) => {
            let menuHeadings = getHeadingMenu(item.menu_headings);
            menus[item.type] = addDividers(menuHeadings);
        });
    } catch (err) {
        console.log(err);
    }
    return menus;
};
